import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Quicklinks from '../../../components/Quicklinks';
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import Video from '../../../components/Video';

const ElephantToothpaste = () => (
  <Layout
    title="Elephant Toothpaste - Science at Home"
    className="science-starters has-video"
  >
    {/* Intro Title and Video */}
    <Section className="science-starters__video">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../science-at-home">Science at Home</Link>
          </li>
          <li className="breadcrumb active">Elephant Toothpaste</li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">Elephant Toothpaste</h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link to="../c2-balloon" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <Video
                guidSrc="a1a34476-12b4-4f58-a072-0ad1b8d88303"
                className="de-vid"
              ></Video>
              <Link to="../rainbow-bubble-snake" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Main Content */}
    <Section className="pt-0 pb-6">
      <Container>
        <Row className="mb-4">
          <Column size={8} offset={1}>
            <h2 className="science-at-home__intro">
              Watch up-close to see how catalysts help ignite chemical
              reactions. Join Camille Schrier, a scientist who was crowned Miss
              America 2020, as she shows you how to make a chemical reaction so
              big it’s fit for elephants!
            </h2>
          </Column>
        </Row>
        <Row>
          <Column size={8} offset={1} className="science-home__content">
            {/* Quick Links */}
            <Quicklinks
              className="pl-0"
              title="Quicklinks:"
              smoothScroll
              links={[
                {
                  target: '#background',
                  label: 'Background',
                },
                {
                  target: '#key-concepts',
                  label: 'Key Concepts',
                },
                {
                  target: '#materials',
                  label: 'Materials',
                },
                {
                  target: '#safety',
                  label: 'Safety',
                },
                {
                  target: '#standards',
                  label: 'Standards',
                },
              ]}
            />
            {/* Background */}
            <h3 id="background">Background</h3>
            <p>
              The elephant toothpaste experiment is so dramatic because the
              reaction happens quickly. Hydrogen peroxide breaks down naturally
              over time, especially when exposed to light, but in this reaction
              the yeast causes that breakdown to happen much quicker. This is
              because yeast is a catalyst - a substance that can help the
              reactants react to each other faster, without becoming part of the
              end products of the reaction. Catalysts were first written about
              in 1794 by a Scottish chemist named Elizabeth Fulhame. She was
              writing about chemical reactions used in dyes and paints, and
              described lots of different ways that metals and other materials
              interact. Since then, chemists and engineers have discovered lots
              of other ways to use catalysts, and they help make many materials
              that we use today. Yeast is a naturally occurring catalyst that is
              good at breaking down hydrogen peroxide (H2O2) into water (H2O)
              and oxygen (O2). In this experiment, we will see how that process
              looks when it happens quickly.
            </p>
            {/* Key Concepts */}
            <h3 id="key-concepts">Key Concepts</h3>
            <ul>
              <li>Molecular Interaction</li>
              <li>Energy Conservation</li>
            </ul>
            {/* Materials */}
            <h3 id="materials">Materials</h3>
            <ul>
              <li>An adult helper</li>
              <li>Safety glasses</li>
              <li>½ cup hydrogen peroxide</li>
              <li>1 packet of dry yeast (or 1 tablespoon)</li>
              <li>Dish soap</li>
              <li>Plastic water bottle</li>
              <li>Baking pan or tray (to contain the mess)</li>
              <li>Food coloring (optional)</li>
            </ul>
            <h3>Preparation</h3>
            <ul>
              <li>
                Put the yeast in a small cup or bowl, and add about 3
                tablespoons of warm water to activate it.
              </li>
              <li>
                Put your safety glasses on. Mix the hydrogen peroxide and a few
                squirts of dish soap in the plastic water bottle. Add food
                coloring to this if you like.
              </li>
              <li>
                Place the plastic water bottle on the baking pan or tray, and
                make sure you are in an area that is easy to clean up- bathroom,
                kitchen or outside are all good options.
              </li>
              <li>
                Make a prediction about what is going to happen when you add the
                yeast to the hydrogen peroxide and soap mixture.
              </li>
              <li>
                Carefully pour the yeast mixture into the bottle and stand back-
                watch what happens as the yeast and hydrogen peroxide mix.
              </li>
              <li>
                Be sure to clean up when you are done. Your mixture can be
                dumped down the sink, and all of the containers and measuring
                tools you used can be washed and put away. Make sure to clean up
                any mess that was left on the ground or floor as well.
              </li>
            </ul>
            <h3>Observation and Results</h3>
            <p>
              You should see the mixture start to foam and shoot out the top of
              the plastic bottle. This is happening because the hydrogen
              peroxide is breaking down into water and oxygen very quickly due
              to the yeast. The yeast is acting as a catalyst to speed up the
              reaction. The oxygen gas takes up a lot more space than when it
              was in liquid form, so it starts to leave the bottle. The foam is
              caused by the dish soap forming bubbles with the oxygen as it is
              produced.
            </p>
            <p>
              You may have also noticed some steam coming off of the foam, or
              that it feels warm to the touch. That is because this reaction is
              exothermic - it releases heat as a form of energy. It takes more
              energy to hold the molecules together in the form of hydrogen
              peroxide than it does to hold the oxygen and water molecules
              together, so when the peroxide molecules break up, that extra
              energy has to go somewhere. It gets released as heat.
            </p>
            <p>
              What would happen if you tried the reaction without soap? What do
              you think you would see? Does changing the container you do the
              reaction in change the way the foam moves? Can you think of any
              other exothermic reactions? Why would it be useful to have a
              reaction that can produce heat? Can you think of other chemical
              reactions that mix liquids to produce gas? Are those similar or
              different to this reaction? Why do you think yeast naturally
              breaks down hydrogen peroxide? There are lots of ways to find out
              more about this reaction. Do some research on your own and see
              what other people have done with it.
            </p>
            {/* Safety */}
            <h3 id="safety">Safety First and Adult Supervision</h3>
            <ul>
              <li>Follow the experiment’s instructions carefully.</li>
              <li>A responsible adult should assist with each experiment.</li>
              <li>
                While science experiments at home are exciting ways to learn
                about science hands-on, please note that some may require
                participants to take extra safety precautions and/or make a
                mess.
              </li>
              <li>
                Adults should handle or assist with potentially harmful
                materials or sharp objects.
              </li>
              <li>
                Adult should review each experiment and determine what the
                appropriate age is for the student’s participation in each
                activity before conducting any experiment.
              </li>
            </ul>
            {/* Standards */}
            <h3 id="standards">
              Next Generation Science Standard (NGSS) Supported - Disciplinary
              Core Ideas
            </h3>
            <p>
              This experiment was selected for Science at Home because it
              teaches NGSS Disciplinary Core Ideas, which have broad importance
              within or across multiple science or engineering disciplines.
            </p>
            <p>
              Learn more about how this experiment is based in NGSS Disciplinary
              Core Ideas.
            </p>
            <p className="bold">
              Engineering Design (ETS)1: Engineering Design
            </p>
            <div className="accordion">
              <div className="accordion__group">
                <div className="accordion__item">
                  <input type="checkbox" id="chck1"></input>
                  <label className="label" for="chck1">
                    PS1.A: Structure and Properties of Matter
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>2-PS1-1.</em> Different kinds of matter exist and
                        many of them can be either solid or liquid depending on
                        temperature. Matter can be described and classified by
                        its observable properties.\n2-PS1-2. Different
                        properties are suited to different purposes.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>5-PS1-1.</em> Matter of any type can be subdivided
                        into particles that are too small to see, but even then,
                        the matter still exists and can be detected by other
                        means.
                      </li>
                      <li>
                        <em>5-PS1-2.</em> The amount (weight) of matter is
                        conserved when it changes form, even in transitions in
                        which it seems to vanish.
                      </li>
                      <li>
                        <em>5-PS1-3.</em> Measurements of a variety of
                        properties can be used to identify materials.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS1-1.</em>
                        <ul>
                          <li>
                            Substances are made from different types of atoms,
                            which combine with one another in various ways.
                          </li>
                          <li>
                            Atoms form molecules that range in size from two to
                            thousands of atoms.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <em>MS-PS1-2,3.</em>
                        <ul>
                          <li>
                            Each pure substance has characteristic physical and
                            chemical properties that can be used to identify it.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <em>MS-PS1-4.</em>
                        <ul>
                          <li>
                            Gases and liquids are made of molecules or inert
                            atoms that are moving about relative to each
                            other.\nIn a liquid, the molecules are constantly in
                            contact with others.
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS1-1.</em> Each atom has a charged substructure
                        consisting of a nucleus, which is made of protons and
                        neutrons, surrounded by electrons.
                      </li>
                      <li>
                        <em>HS-PS1-2.</em> The periodic table orders elements
                        horizontally by the number of protons in the atom’s
                        nucleus and places those with similar chemical
                        properties in columns. The repeating patterns of this
                        table reflect patterns of outer electron states.
                      </li>
                      <li>
                        <em>HS-PS1-3.</em> The structure and interactions of
                        matter at the bulk scale are determined by electrical
                        forces within and between atoms.
                      </li>
                      <li>
                        <em>HS-PS1-4.</em> Stable forms of matter are those in
                        which the electric and magnetic field energy is
                        minimized. A stable molecule has less energy than the
                        same set of atoms separated; one must provide at least
                        this energy in order to take the molecule apart
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck2"></input>
                  <label className="label" for="chck2">
                    PS1.B: Chemical Reactions
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>2-PS1-4.</em> Heating or cooling a substance may
                        cause changes that can be observed. Sometimes these
                        changes are reversible, and sometimes they are not.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>5-PS1-4.</em> When two or more different substances
                        are mixed. A new substance with different properties may
                        be formed.
                      </li>
                      <li>
                        <em>5-PS1-2.</em> No matter what reaction or change in
                        properties occurs, the total weight of the substances
                        does not change.\nGrades 6-8\nMS-PS1-2. Substances react
                        chemically in characteristic ways.
                      </li>
                      <li>
                        <em>MS-PS1-3.</em> In a chemical process, the atoms that
                        make up the original substances are regrouped into
                        different molecules, and these new substances have
                        different properties from those of the reactants.
                      </li>
                      <li>
                        <em>MS-PS1-5.</em> The total number of each type of atom
                        is conserved, and thus the mass does not change.
                      </li>
                      <li>
                        <em>MS-PS1-6.</em> Some chemical reactions release
                        energy, others store energy.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS1-4,5.</em> Chemical processes, their rates,
                        and whether or not energy is stored or released can be
                        understood in terms of the collisions of molecules and
                        the rearrangement of atoms into new molecules with
                        consequent changes in the sum of all bond energies in
                        the set of molecules that are matched by the changes in
                        kinetic energy.
                      </li>
                      <li>
                        <em>HS-PS1-6.</em> In many situations, a dynamic and
                        condition-dependent balance between a reaction and the
                        reverse reaction determines the numbers of all types of
                        molecules present.
                      </li>
                      <li>
                        <em>HS-PS1-7.</em> The fact that atoms are conserved,
                        together with the knowledge of the chemical properties
                        of the elements involved, can be used to describe and
                        predict chemical reactions.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Column>
          {/* PI team still trying to decide if we're going 
              to use these callouts prior to site launch
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="career-right-image">
                <Image filename="ElephantToothpaste-Profile-Square.jpg" />
              </div>
              <div className="global__profile-content">
                <h3>Camille Schrier</h3>
                <h4>Scientist, 3M</h4>
              </div>
            </div>
          </Column> */}
        </Row>
      </Container>
    </Section>
    {/* Explore Additional Science at Home Videos and Activities */}
    <Section className="science-at-home__cards pb-6">
      <Container>
        <Row>
          <Column fullWidth>
            <h2>Explore Additional Science at Home Videos and Activities</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="C02Balloon-Thumbnail.png" />
              <div className="card__content">
                <h3>
                  C0<sub>2</sub> Balloon
                </h3>
                <h4>
                  Baking soda and acid reactions in baking make things puff and
                  rise.
                </h4>
                <p>
                  But how could you use this same chemical reaction to blow up a
                  balloon?
                </p>
              </div>
              <Link to="../c2-balloon" className="button bg-gradient-purple">
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="RainbowSnake-Thumbnail.png" />
              <div className="card__content">
                <h3>Rainbow Bubble Snake</h3>
                <h4>
                  Everyone loves bubbles, but have you ever thought about how
                  they form?
                </h4>
                <p>
                  In this video, special guest, Kate the Chemist, shows you the
                  science behind bubbles. With just a few simple materials,
                  you’ll learn how to make a bright and colorful bubble snake
                  using your breath, soap, water and a plastic water bottle.
                </p>
              </div>
              <Link
                to="../rainbow-bubble-snake"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="FidgetSpinner-Thumbnail.png" />
              <div className="card__content">
                <h3>Fidget Spinner</h3>
                <h4>
                  Your teacher might not love when you have one in class, but
                  the physics behind fidget spinners are truly head-spinning!
                </h4>
                <p>
                  Join 3M scientist Tesha R.-Alston Dampier as she shows you how
                  a spinning motion changes the way things move.
                </p>
              </div>
              <Link
                to="../fidget-spinner"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default ElephantToothpaste;
